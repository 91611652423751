<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    :overlay="true"
    max-width="500px"
    transition="dialog-transition"
  >
    <v-card align="center">
      <v-col>
        <v-icon color="success" size="140">mdi-account-check</v-icon>
        <v-card-text class="headline mb-1">
          {{ title }}
        </v-card-text>
      </v-col>
      <v-card-text>
        {{ text }}
        <v-progress-circular
          class="ml-3"
          size="20"
          indeterminate
        ></v-progress-circular>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },

  watch: {
    dialog() {
      this.timeRedirect = setTimeout(() => {
        window.location.href = process.env.VUE_APP_ROOT_SPA + "login";
      }, 3000);
    }
  }
};
</script>

<style></style>
